.navbar li {
    list-style: none;

}
 .navbar ul {
    display: flex;
    flex-direction: column;
    gap: 1em;
 }
.navbar a {
    text-decoration: none;
    color: white;
    border-radius: 2px;
    font-size: 1.2rem;
}
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: radial-gradient(circle at center, #052546, #000000);
}

.navbar-sec2 {
    font-size: 1.5em;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    transition: transform 0.3s ease;
}

.navbar-sec2-icon {
    font-size: 1.5em;
    margin-right: 1em;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

