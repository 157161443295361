.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #262626, #1a1a1a, #0a0a1e, #05050c);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loading-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-image: url(../../media/canabis_icon.png);
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    100% { transform: rotate(360deg); }
  }
  