.Plantform { 
  width: 90vw;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

input[type="text"],
textarea,
input[type="file"] {
  width: 60%;
  min-width: 300px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 1rem;
}

textarea {
  resize: vertical;
}

button[type="submit"] {
  width: 10vw;
  min-width: 100px;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #008CBA;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

button[type="submit"]:hover {
  background-color: #006B9F;
}
