.measuring-values {
  background-image: url('../../media/raven.png');
  background-size: cover;
  background-position: left;
}
.inactive {
  background-image: url('../../media/night.png');
  background-size: cover;
  background-position: top;
  box-shadow: inset 0 0 10px 10px #000000;

}
.active {
  background-image: url('../../media/blue_sky.png');
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 10px 10px #000000;
  color: #000000;
}
.measuring-values-heading {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  /* box-shadow: inset 0px 0px 10px 5px white; */
}

.measuring-values-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
  margin-bottom: 1rem;

}
@media (max-width: 900px) {
  .measuring-values-grid {
    /* For screens smaller than 768px, display 2 columns */
    grid-template-columns: repeat(2, 1fr);
  }
}


.measuring-value-item-label {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-right: 10px;
  width: 100%;
}

.measuring-value-item-value {
  width: 100%;
  display: flex;
  justify-content: center;  
}


.measuring-value-box {
  display: flex;
  width: 100%;
}

.measuring-value-item-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.measuring-value-item-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.measuring-value-item {
  /* background: linear-gradient(to right, #1e90ff, #000000);  */
  /* background: radial-gradient(circle at center, #00000038, transparent); */
  border-radius: 50%;
  font-weight: bold;
  font-size: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.unit {
  font-size: 1.5rem;
  font-weight: normal;
}