.settings-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  
  .setting {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin: 10px;
    width: 200px;
    color: rgba(255, 255, 255, 0.856);
    font-size: 1rem;
    width: 10vw;
    min-width: 150px;
    gap: 1rem;
    transition: all .2s ease-in-out;
    
    font-weight: bold;
  }

  @media (max-width: 600px) {
    .setting {
      width: 80vw;
    }
  }



  
  .Auto {
    background: linear-gradient(to right, #0a0a0aa2, #5fbf91);
    background: radial-gradient(circle at center, #0f5a36, transparent);
    font-weight: bold;
  }
  
  .An {
    background: linear-gradient(to right, #0a0a0aa2, #e53835);
    background: radial-gradient(circle at center, #0f5a36, transparent);

  }
  
  .Aus {
    background: linear-gradient(to right, #0a0a0aa2, #e53835);
    background: radial-gradient(circle at center, #e53835, transparent);

  }
  
  .setting:hover {
    cursor: pointer;
    scale: 1.1;
  }