.plant-steckbrief {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    max-width: 300px;
  }
  
  .plant-steckbrief:hover {
    transform: translateY(-5px);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  }
  
  .plant-steckbrief img {
    width: 20vw;
    max-width: 300px;
    min-width: 250px;
    height:30vh;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 1rem;
  }
  
  .plant-steckbrief h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .plant-steckbrief p {
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
  }