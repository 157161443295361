.plant-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    
   
  }
.plant-details {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  
}
.plant-desc-container {
  display: flex;
  justify-content: center;
  gap: 1em;
}
.plant-desc-container img {
  width: 100%;
  max-width: 800px;
}
.plant-img-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.plant-details img {
  max-height: 70vh;
}

.plus-button {
  width: 60px;
  height: 60px;
  background-color: transparent;
  color: green;
  font-size: 48px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plus-button a{
  text-decoration: none;
  color: green;
}