.landingpage {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: white;
  }


.landingpage > * {
    border-radius: 4px;   
    width: 80vw;

  }

  .linkcontainer {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  
  .linkcontainer h1 {
    margin-top: 0;
  }
  
  .linkcontainer a {
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
    transition: transform 0.3s ease-in-out;
    color: inherit;
  }
  
  .linkcontainer img {
    max-width: 25vw;
    min-width: 300px;
    height: auto;
    border: none;
    box-shadow: 0 0 5px #ccc;
    transition: all 0.3s ease;
  }
  
  .linkcontainer a:hover {
    transform: scale(1.05);

  }
  .link:hover {
    transform: scale(1.05);
  }

  .link {
    background-size: cover;
    width: 30vw;
    min-width: 350px;
    height: 30vh;
    transition: all 0.3s ease;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: underline;  
    border-radius: 30px;
    cursor: pointer;
  }
  
 