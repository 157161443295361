  .lightswitch-holder {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  
  .light-switch-container {
    width: 100%;
    background: radial-gradient(circle at center, #242424, black);
    padding: 1em;
  }
  
  .light-switch-heading-container {
    display: flex;
  }
  
  .light-switch-heading {
    margin-top: 0;
    width: fit-content;
  }
  
  .light-switch-inputs {
    display: flex;
    flex-direction: column;
  }
  
  .light-switch-inputs label {
    margin-bottom: 0.5rem;
  }
  
  .light-switch-time-input {
    background-color: #3e424b67;
    border: none;
    color: white;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 4px;  
    font-size: 1rem; 
  }
  
  .light-switch-time-input:focus {
    outline: none;
  }

  .light-switch-timeactive-container {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .light-switch-timeactive-container > * {
    margin: 0;
    
  }

  .light-switch-buttons-containers {
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;
  }
  
  .light-switch-button {
    border: none;
    color: rgba(255, 255, 255, 0.938);
    padding: 0.5rem;
    font-weight: bold;
    border-radius: 4px;   

  }
  .activebtn {
    background-color: #207CB7;
    
  }
  .inactivebtn {
    background-color: #0E1B34;
  }
  
  .light-switch-button:hover {
    cursor: pointer;
    background-color: #45b6d3;
  }
  
  .light-switch-profile-selector {
    margin-bottom: 1rem;
    display: flex;
    gap: 1rem;
  }

  .light-switch-button-1 {
    background-color: transparent;
    border: none;
    background: radial-gradient(circle at center, #0f5a36, transparent);
    color: white;
    cursor: pointer;
    border-radius: 4px;
  }

  .light-switch-button-0 {
    background-color: transparent;
    border: none;
    background: radial-gradient(circle at center, #e53835, transparent);
    color: white;
    cursor: pointer;
    border-radius: 4px;

  }
  .active {
    background-color: white;
  }
