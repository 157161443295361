.tent {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    color: white;
  }
  
  .tent > * {
    margin: 10px;
    transition: transform 0.3s ease-in-out;
    /* background: linear-gradient(to bottom, #0a0a0a, #1c1c1c, #0a0a0a); */
    /* background: linear-gradient(to bottom, #0a0a0aa2, #1c1c1c85, #0a0a0aa2); */
    /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4); */
    padding: 1rem;
    border-radius: 4px;   
    width: 90vw;
  }

  @media (max-width: 767px) {
    .container > .child {
      flex-basis: calc(50% - 20px);
    }
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .container > .child {
      flex-basis: calc(33.33% - 20px);
    }
  }
  
  @media (min-width: 1024px) {
    .container > .child {
      flex-basis: calc(25% - 20px);
    }
  }
  
  .container > *:hover {
    transform: translateY(-5px);
  }
  
  .lightswitch-holder {
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80vw;
    background-color: rgba(0, 0, 0, 0.774);
  }